import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { fade, makeStyles, withStyles } from "@material-ui/core/styles"
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Container,
  MenuItem,
} from "@material-ui/core"

import Layout from "../components/layout"
import Heading from "../components/heading"
import contact1 from "../images/contact/contact1.jpg"

const queryList = [
  "Products",
  "Custom Software",
  "Consulting",
  "Training",
  "Other",
]

const CustomTextField = withStyles(theme => ({
  root: {
    marginTop: 10,
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "rgba(0,0,0,0)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}))(TextField)

const Contact = () => {
  const classes = useStyles()
  const [standard, setStandard] = useState(null)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [type, setType] = useState("")
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [endpoint, setEndpoint] = useState("contact")

  useEffect(() => {
    setSuccess(false)
    setErrorMessage(false)
  }, [])

  const handleClick = e => {
    e.preventDefault()
    if (standard === null) {
      const query = JSON.stringify({
        name: name,
        phone: phone,
        email: email,
        type: type,
        message: message,
        endpoint: endpoint,
      })

      fetch("https://phaidelta.com:3001/leads", {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: query,
      })
        .then(({ data }) => {
          setSuccess(true)
        })
        .catch(err => {
          setErrorMessage(true)
        })
    } else {
      console.log("Something went Wrong")
    }
  }

  const query = useStaticQuery(graphql`
    query {
      seo: strapiSeo {
        contactPage {
          pagetitle
          description
          keywords
        }
      }
    }
  `)

  return (
    <Layout
      siteTitle={query.seo.contactPage.pagetitle ?? "Contact"}
      description={query.seo.contactPage.description ?? ""}
      keywords={query.seo.contactPage.keywords ?? ""}
    >
      <Heading
        title="Contact"
        content="Our company is constantly evolving and growing. We provide wide
        range of services. Our mission is to provide best solution that
        helps everyone."
      />

      <Box pb={10} pt={15} className={classes.clip}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} md={4}></Grid>
            <Grid
              item
              xs={12}
              md={8}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h4"
                  color="default"
                  align="center"
                  style={{ opacity: 0.7, letterSpacing: 5 }}
                >
                  GET IN TOUCH
                </Typography>
              </Box>
              <Box py={3}>
                <Box bgcolor="#999" width={150} height={3} />
              </Box>
              <form onSubmit={handleClick}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CustomTextField
                    InputProps={{ disableUnderline: true }}
                    variant="filled"
                    size="small"
                    label="Name"
                    className={classes.formTextField}
                    fullWidth
                    required
                    onChange={event => {
                      setName(event.target.value)
                    }}
                  />
                  <CustomTextField
                    InputProps={{ disableUnderline: true }}
                    variant="filled"
                    size="small"
                    label="Phone"
                    className={classes.formTextField}
                    fullWidth
                    onChange={event => {
                      setPhone(event.target.value)
                    }}
                  />
                  <CustomTextField
                    InputProps={{ disableUnderline: true }}
                    variant="filled"
                    size="small"
                    label="Email"
                    type="email"
                    className={classes.formTextField}
                    fullWidth
                    required
                    onChange={event => {
                      setEmail(event.target.value)
                    }}
                  />
                  <CustomTextField
                    InputProps={{ disableUnderline: true }}
                    variant="filled"
                    size="small"
                    select
                    label="Query"
                    className={classes.formTextField}
                    fullWidth
                    onChange={event => {
                      setType(event.target.value)
                    }}
                  >
                    {queryList.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  <CustomTextField
                    InputProps={{ disableUnderline: true }}
                    variant="filled"
                    size="small"
                    label="Your Message..."
                    multiline
                    rows={3}
                    className={classes.formTextField}
                    onChange={event => {
                      setMessage(event.target.value)
                    }}
                  />
                  <TextField
                    id="standard-basic"
                    variant="filled"
                    label="Standard"
                    fullWidth
                    style={{ display: "none" }}
                    value={standard}
                    onChange={event => {
                      setStandard(event.target.value)
                    }}
                  />
                  <Box pt={2} width={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                    >
                      SEND
                    </Button>
                  </Box>
                </Box>
              </form>
              {success ? (
                <Typography
                  variant="caption"
                  color="primary"
                  align="center"
                  style={{ maxWidth: "250px" }}
                >
                  Thank you for contacting us! We'll get back to you in 48
                  hours.
                </Typography>
              ) : errorMessage ? (
                <Typography variant="subtitle1" color="error" align="center">
                  Error!
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5280.069925794142!2d73.81383903994741!3d15.474744412704167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfc0c6c4cb4a4d%3A0xfbe545a36f3b968!2sAdwalpalkar%20Shelter%2C%20Kerant%2C%20Caranzalem%2C%20Goa%20403002!5e0!3m2!1sen!2sin!4v1592808894430!5m2!1sen!2sin"
                width="100%"
                height="450"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </Grid>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12} container justify="center">
                <Box textAlign="center" py={5}>
                  <Typography
                    variant="h5"
                    color="primary"
                    gutterBottom
                    align="center"
                  >
                    Address
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    Adwal Palkar Shelters
                    <br />
                    Caranzalem
                    <br />
                    Goa - 403 002 India
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} container justify="center">
                <Box textAlign="center" py={5}>
                  <Typography
                    variant="h5"
                    color="primary"
                    gutterBottom
                    align="center"
                  >
                    Contact Us At
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    +91 9819652514
                    <br />
                    +91 8010768815
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} container justify="center">
                <Box textAlign="center" py={5}>
                  <Typography
                    variant="h5"
                    color="primary"
                    gutterBottom
                    align="center"
                  >
                    Send Us an Email
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    bd@phaidelta.com
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  formTextField: {
    minWidth: "300px",
  },
  clip: {
    // clipPath: "polygon(0 100%, 0 0, 50% 10%, 100% 0, 100% 100%)",
    backgroundImage: `Url(${contact1})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
}))

export default Contact
